(function ($) {
  'use strict';

  Drupal.behaviors.contentBlockV1 = {
    attach: function (context) {
      var $module = $('.js-content-block--v1', context);

      $module.off('click.contentBlock').on('click.contentBlock', function (e) {
        e.preventDefault();
        if ($(this).attr('data-href').value !== '') {
          window.open($(this).data('href'));
        }
      });
    }
  };
})(jQuery);
